<template>
    <div class="container d-flex justify-content-center">
      <ErrorComponent/>
    </div>
</template>

<script >
import ErrorComponent from '../components/ErrorComponent.vue'

export default {
  name: 'App',
  components: {
    ErrorComponent
  }
}
</script>

<style lang="scss" scoped>

</style>