<template>
  <div id="app">
    <HeaderComponent></HeaderComponent>
    <router-view></router-view>
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue'

export default {
  name: 'App',
  components: {
    HeaderComponent
  }
}
</script>

<style>
body {
  background-image: url("https://cliente.vivanet.co.mz/images/bg-image-vivanet.png");
  background-repeat: no-repeat;
  background-size: cover !important;
}
</style>
