<template>
    <div class="container d-flex justify-content-center mt-4">
      <h1 style="color:white">CONTEÚDO NÃO ENCONTRADO</h1>
    </div>
</template>

<script >

export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style lang="scss" scoped>

</style>