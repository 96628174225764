<template>
    <div class="container d-flex justify-content-center">
      <FormComponent/>
    </div>
</template>

<script >
import FormComponent from '../components/FormComponent.vue'

export default {
  name: 'App',
  components: {
    FormComponent
  }
}
</script>

<style lang="scss" scoped>

</style>