import { createApp } from 'vue'

import App from './App.vue'
import router from './router';
import store from './store/state.js';
import VueNumberFormat from 'vue-number-format'



const app = createApp(App)
app.use(VueNumberFormat, { prefix: 'MT ', decimal: ',', thousand: '.' })
app.use(router);
app.use(store)

app.mount('#app')
