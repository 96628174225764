<template>
    <div class="form-holder">
     <p class="h3 mb-4 dados" v-if="bank != 'bank'">Subscrição efectuada com sucesso</p>
     <p class="h3 mb-4 dados" v-if="bank == 'bank'">Está a um passo de efectuar com sucesso a sua subscrição</p>
     <p v-if="bank == 'bank'">
      Para tal, envie o comprovativo da transferência ou depósito bancário para o endereço de email info@vivanet.co.mz. Consulte o seu endereço de email para mais detalhes!
     </p>

     <strong>{{ invoice.name }}</strong>
      <hr>
     <p>{{ invoice.plan }}  {{ invoice.instalation_value }}</p> 
<hr>

   </div>
 </template>

 <script setup>
import { useRoute } from 'vue-router';
import { computed } from 'vue';

const route = useRoute()

const inv = computed(() => {
  const invoiceParam = route.query.invoice;
  if (invoiceParam) {
    try {
      return JSON.parse(invoiceParam);
    } catch (error) {
      console.error('Error parsing plan data:', error);
    }
  }
  return null;
});
const invoice = inv.value || {
  name: '',
  id: ''
};

const bnk = computed(() => {
  const bnkParam = route.query.bank;
  if (bnkParam) {
    try {
     // console.log(bnkParam)
      return bnkParam;
    } catch (error) {
      console.error('Error parsing plan data:', error);
    }
  }
  return null;
});
const bank = bnk.value || {
  name: '',
  id: ''
};

 </script>

 <style lang="css" scoped>
 .form-holder{
     background-color: #fff;
     margin-top: 100px;
     padding: 30px;
     border-radius: 30px;
     width: 1000px;
 }
 .dados{
     font-weight: 800;
     color: #ED1B68;
 }
 .viva-active{
   font-size: 18px;
   border: #ED1B68 solid 2px;
   border-radius: 25px;
 }
 label {
   color: #364FA2 !important;
   font-weight: 600;
 }
 input{
     color: #364FA2;
 }
 .btn-color{
     background-color: #ED1B68;
     border: #ED1B68;
 }
 input[type=text] {
     color: #364FA2;
     border: 2px solid #364FA2;
 }
 input[type=email] {
     color: #364FA2;
     border: 2px solid #364FA2;
 }
 input[type=select] {
     color: #364FA2;
     border: 2px solid #364FA2;
 }
 </style>