import { createRouter, createWebHistory } from "vue-router";
import store from '../store/state'
import HomePage from "../views/HomePage.vue";
import CheckoutPage from "../views/CheckoutPage.vue";
import HoldonPage from "../views/HoldonPage.vue";
import SuccessPage from "../views/SuccessPage.vue";
import ErrorPage from "../views/ErrorPage.vue";
import InfoPage from "../views/InfoPage.vue";
import ClientsPage from "../views/ClientsPage.vue";
import LoginPage from "../views/LoginPage.vue";
import SuccessMastercardPage from "../views/SuccessMastercardPage.vue"
import NotFoundPage from "../views/NotFoundPage.vue"

const routes = [
  { path: "/", component: HomePage },
  { path: "/checkout", component: CheckoutPage },
  { path: "/checkout/client/:client/plan/:plan/plan-type/:plnType", component: CheckoutPage },
  { path: "/checkout/client/:client/plan/:plan/plan-type/:plnType/province/:province", component: CheckoutPage },
  { path: "/holdon", component: HoldonPage },
  { path: "/success", component: SuccessPage },
  { path: "/success_master_card/mastercard", component: SuccessMastercardPage},
  { path: "/error", component: ErrorPage },
  { path: "/info", component: InfoPage },
  { path: "/plan/:id", component: HomePage },
  { path: '/plan/:id/tipo/:tipo', component: HomePage },
  { path: "/login", component: LoginPage, name: 'Login' },
  { path: "/list-clients", component: ClientsPage, name: 'Dashboard', meta: { requiresAuth: true } },
  { path: "/:catchAll(.*)", component: NotFoundPage }, // 404 route
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
});

router.beforeEach((to, from, next) => {
  if(to.meta.requiresAuth && !store.state.user.token) {
    next({ name: 'Login' })
  }else if (store.state.user.token && (to.meta.isGuest)){
    next({ name: 'Dashboard' })
  }else {
    next()
  }
})

export default router;