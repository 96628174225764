<template>
    <div class="container d-flex justify-content-center">
        <table class="table">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Nome Completo</th>
        <th scope="col">Email</th>
        <th scope="col">Telefone</th>
        <th scope="col">Província</th>
        <th scope="col">Plano</th>
        <th scope="col">Preço</th>
        <th scope="col">Valor Pago</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="subscription,i in subscriptions.data" :key="subscription">
        <th scope="row">{{ i }}</th>
        <td>{{ subscription.client.first_name +" "+ subscription.client.last_name}}</td>
        <td>{{ subscription.client.email }}</td>
        <td>{{ subscription.client.cell }}</td>
        <td>{{ subscription.client.province }}</td>
        <td>{{ subscription.plan.name }}</td>
        <td>{{ subscription.plan.price }}MT</td>
        <td>{{ subscription.paid_value }}MT</td>
      </tr>
    </tbody>
  </table>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import axios from 'axios';

const subscriptions = ref([]);

// Fetch subscriptions when the component is mounted
onMounted(async () => {
  try {
    const response = await axios.get('https://api.vivanet.co.mz/api/v1/subscription');
    subscriptions.value = response.data;
    console.log(response.data)
  } catch (error) {
    console.error('Error fetching subscriptions:', error);
  }
});
</script>

<style scoped>

</style>


