<template>
<div class="" style="background-color: #fff">
   <div class="container">
      <header class="d-flex flex-wrap justify-content-center py-3 mb-4">
        <a href="https://vivanet.co.mz" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
          <svg class="bi me-2" width="40" height="32"><use xlink:href="#bootstrap"/></svg>
          <span class="fs-4">
            <img src="https://cliente.vivanet.co.mz/images/logo-vivanet.png" class="logo"/>
          </span>
        </a>

        <ul class="nav nav-pills align-items-center">
          <li class="nav-item"><a href="https://vivanet.co.mz" class="nav-link viva" aria-current="page">Home</a></li>
          <li class="nav-item"><a href="https://vivanet.co.mz/" class="nav-link viva">Pacote Comercial</a></li>
          <li class="nav-item"><a href="https://vivanet.co.mz/" class="nav-link viva">Sobre a VIVAnet</a></li>
          <li class="nav-item"><a href="https://vivanet.co.mz/" class="nav-link viva">Cobertura</a></li>
          <li class="nav-item"><a href="https://vivanet.co.mz/" class="nav-link viva">Como Funciona</a></li>
          <li class="nav-item"><a href="https://vivanet.co.mz/" class="nav-link viva-active">Fale Connosco</a></li>
        </ul>
      </header>
    </div>
  </div>
</template>

<style scoped>
.logo{
  width: 160px !important;
}
.viva{
  font-size: 18px;
  font-weight: bold;
}
.viva-active{
  font-size: 18px;
  border: #ED1B68 solid 2px;
  border-radius: 25px;
}
.nav-link {
  color: #364FA2 !important;
}

.viva-color-violet{
  color: #364FA2;
}
.viva-color-rose{
  color: #ED1B68;
}
</style>


<script>
export default {
  name: 'HelloWorld',

}
</script>

