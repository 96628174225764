<template>
  <div class="form-holder">
    <p class="h3 mb-4 dados">Pagamento</p>

    <div class="container">
      <main>

        <div class="row g-5">

          <div class="col-md-5 col-lg-4 order-md-last">
            <ul class="list-group mb-3">
              <li class="list-group-item d-flex justify-content-between lh-sm">
                <div>
                  <h6 class="my-0 pb-1">{{ plan.name }} - <span style="text-transform: capitalize;">{{ typeP }}</span></h6>
                  <span class="text-body-secondary">Velocidade de download {{ plan.nominal_speed_download
                  }}</span><br>
                  <span class="text-body-secondary">Velocidade de upload {{ plan.nominal_speed_upload
                  }}</span><br>
                  <span class="text-body-secondary">{{ plan.type }}</span><br>
                </div>
              </li>

              <li class="list-group-item d-flex justify-content-between">
                <span>Mensalidade</span>
                <span style="display: none;">{{ display_preco = plan.price / 1.16 }}</span>

                <VueNumberFormat v-model:value="display_preco" class="number_format_link"
                  :options="{ precision: 2, prefix: '', suffix: ' MT', decimal: '.', thousand: ',' }" />
              </li>

<!-- CAUÇÃO DISPLAY PRICE-->
              <li v-if="typeP == 'normal'" class="list-group-item d-flex justify-content-between">
                <span>Caução<br></span>
                <span style="display: none;">{{ display_caucao_price = (plan.price+plan.price) / 1.16 }}</span>
                <VueNumberFormat v-model:value="display_caucao_price" class="number_format_link"
                  :options="{ precision: 2, prefix: '', suffix: ' MT', decimal: '.', thousand: ',' }" />
              </li>
<!-- CAUÇÃO DISPLAY PRICE -->


              <li class="list-group-item d-flex justify-content-between">
                <span>Taxa de instalação<br><small>(Pagamento único)</small></span>
                <span style="display: none;">{{ display_instalacao = instalation_tax / 1.16 }}</span>
                <VueNumberFormat v-model:value="display_instalacao" class="number_format_link"
                  :options="{ precision: 2, prefix: '', suffix: ' MT', decimal: '.', thousand: ',' }" />
              </li>
              <li v-if="typeP == 'flexivel'" class="list-group-item d-flex justify-content-between">
                <span>Equipamento<br><small>(Pagamento único)</small></span>
                <span style="display: none;">{{ display_equipment_price = equipment_price / 1.16 }}</span>
                <VueNumberFormat v-model:value="display_equipment_price" class="number_format_link"
                  :options="{ precision: 2, prefix: '', suffix: ' MT', decimal: '.', thousand: ',' }" />
              </li>


<!--ROTEADOR DISPLAY PRICE-->
            <li class="list-group-item d-flex justify-content-between">
                <form>
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="is_router">
                  <label class="form-check-label" for="exampleCheck1">
                    <span style="color:black !important">Router Wifi<br><small>(Opcional)</small></span>
                  </label>
                </div>
              </form>
                <span style="display: none;">{{ display_router_price = router_price / 1.16 }}</span>
                <VueNumberFormat v-model:value="display_router_price" class="number_format_link"
                  :options="{ precision: 2, prefix: '', suffix: ' MT', decimal: '.', thousand: ',' }" />
            </li>
<!--ROTEADOR DISPLAY PRICE -->



              <li class="list-group-item d-flex justify-content-between">

                <template v-if="typeP == 'normal'">
                  <span style="display:none;">{{ subtotal = display_caucao_price + display_instalacao + display_preco + (is_router == true ? display_router_price : 0) }}</span>
                </template>
                <template v-else-if="typeP == 'flexivel'">
                  <span style="display:none;">{{ subtotal = display_instalacao + display_preco + display_equipment_price + (is_router == true ? display_router_price : 0)}}</span>
                </template>

                <span>Subtotal</span>
                <VueNumberFormat v-model:value="subtotal" class="number_format_link"
                  :options="{ precision: 2, prefix: '', suffix: ' MT', decimal: '.', thousand: ',' }" />
              </li>

                <template v-if="typeP == 'normal' || '' || null || undefined">
                  <span style="display:none;">
                    {{ iva = ((plan.price + (plan.price*2) + instalation_tax) + (is_router == true ? router_price : 0)) - subtotal }}
                  </span>
                </template>
                <template v-else-if="typeP == 'flexivel'">
                  <span style="display:none;">
                    {{ iva = ((plan.price + instalation_tax + equipment_price) + (is_router == true ? router_price : 0)) - subtotal }}
                  </span>
                </template>

              <li class="list-group-item d-flex justify-content-between">
                <span>IVA (16%)</span>
                <VueNumberFormat v-model:value="iva" class="number_format_link"
                  :options="{ precision: 2, prefix: '', suffix: ' MT', decimal: '.', thousand: ',' }" />
              </li>


              <li class="list-group-item d-flex justify-content-between">
                <span>Total</span>

                <template v-if="typeP == 'normal' || '' || null || undefined">
                  <span style="display:none;">
                    {{ the_total = (plan.price + (plan.price*2) + instalation_tax) + (is_router == true ? router_price : 0) }}
                  </span>
                </template>
                <template v-else-if="typeP == 'flexivel'">
                  <span style="display:none;">
                    {{ the_total = (plan.price + instalation_tax + equipment_price) + (is_router == true ? router_price : 0) }}
                  </span>
              </template>


                <VueNumberFormat v-model:value="the_total" class="number_format_link"
                  :options="{ precision: 2, prefix: '', suffix: ' MT', decimal: '.', thousand: ',' }" />
              </li>
            </ul>
          </div>

          <div class="col-md-7 col-lg-8">
            <form class="needs-validation" novalidate @submit.prevent="submitForm">
              <div class="my-3 row">
                <div class="form-check col">
                  <input id="credit" name="paymentMethod" type="radio" class="form-check-input" value="1"
                    v-model="sequency">
                  <label class="form-check-label" for="credit">M-Pesa</label>
                </div>
                <div class="form-check col" >
                  <input id="debit" name="paymentMethod" type="radio" class="form-check-input" value="3"
                    v-model="sequency">
                  <label class="form-check-label" for="debit">Cartão de Débito ou Crédito</label>
                </div>
                <div class="form-check col">
                  <input id="transfer" name="paymentMethod" type="radio" class="form-check-input" value="2"
                    v-model="sequency">
                  <label class="form-check-label" for="transfer">Transferência ou Depósito Bancário</label>
                </div>

              </div>

              <div class="row gy-3" v-if="sequency == 1">
                <div class="col-md-6">
                  <label for="input_number" class="form-label">Número de telefone</label>
                  <input type="text" class="form-control" id="input_number" placeholder="" v-model="telefone" required 
                    maxlength="9">

                  <div class="alert alert-danger alert-dismissible fade show mt-2" role="alert" v-if="error_pay">
                    Ocorreu um erro durante o pagamento
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>
                </div>
                <LoaderComponent v-if="loading" />
                <p style="font-weight: 500;" v-if="loading">Consulte o popup no seu telemóvel e insira o seu PIN.</p>
              </div>
              <div class="row gy-3 mb-3">
                <div class="col" v-if="sequency == 2">
                  <div class="banco" >
                    <p class="dados">Dados bancários</p>
                    Beneficiário: <span class="minibold">Interactive Soluções Tecnológicas (VIVA NET)</span><br>
                    No. de Conta: <span class="minibold">943053304</span><br>
                    NIB: <span class="minibold">0001 0000 0094 3053 3045 7</span><br>
                    Banco: <span class="minibold">Millennium BIM</span>
                  </div>
                </div>
              </div>
              <div class="row gy-3 mb-3" v-if="sequency == 3">

                <div class="alert alert-danger alert-dismissible fade show mt-2" role="alert"
                  v-if="mastercard_error !== ''">
                  {{ mastercard_error }}
                  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>


                <div class="col">
                  <img src="https://cliente.vivanet.co.mz/images/visa.webp" class="bank_card" />

                </div>
              </div>
              <div class="row gy-3 mt-3">
              <div class="col-12">
                  <!--<div class="form-check">
                    <input class="form-check-input" type="checkbox" id="gridCheck" v-model="termos_payment">
                    <label class="form-check-label" for="gridCheck">
                      Li e entendi os <a href="https://vivanet.co.mz/termos-e-condicoes" class="pink-color" target="_blank">termos
                        e pagamento</a>
                    </label>
                  </div>-->
                </div>
              </div>

              <button v-if="!loading && (sequency == 1)" class="btn btn-primary mt-4 btn-color" type="submit">
                <span v-if="sequency == 1">Pagar</span>
                <span v-if="sequency == 2">Finalizar</span>
              </button>
              <button v-if="!loading && (sequency == 2)" class="btn btn-primary mt-4 btn-color" type="submit">
                <span v-if="sequency == 1">Pagar</span>
                <span v-if="sequency == 2">Finalizar</span>
              </button>
            </form>
            {{ mastercard_error }}
            <button v-if="sequency == 3" class="btn btn-primary mt-4 btn-color" @click="payWithMastercard">
              <span>Pagar</span>
            </button>
          </div>

        </div>
      </main>
    </div>
  </div>

</template>

<script setup>
/* eslint-disable */

import axios from 'axios';
import router from '@/router';
import { useRoute } from 'vue-router';
import { ref, onMounted } from 'vue'

import LoaderComponent from './LoaderComponent.vue';

const sequency = ref(1);
const telefone = ref('')
const instalation_tax = ref(2500)
const equipment_price = ref(17000)
const stock_router = ref(false)
const router_price = ref(2990)
const loading = ref(false)
const the_total = ref(0);
const iva = ref(0);
const display_router_price = ref(0)
const display_preco = ref(0)
const display_instalacao = ref(0)
const display_equipment_price = ref(0)
const subtotal = ref(0)
const error_pay = ref(false)
const is_router = ref(true)
/*const termos_payment = ref(true)
const planType = ref([])
const planId = ref('')

const route = useRoute();
const planData = ref()
const pType = ref('normal')*/

const subscription = {
  client_id: null,
  plan_id: null,
  plan_type: '',
  start_date: "2023-06-28",
  end_date: "2023-07-28",
  paid_value: 0,
  monthly_payment: 0,
  billing_addres: "",
  description: null,
  payment_method: "",
  input_number:'',
  province:"",
  is_router: 0
}

const plan = ref({
  name: '',
  nominal_speed_download: '',
  nominal_speed_upload: '',
  type: '',
  price: 0,
  owner: ''
});

const client = ref({
  id: '',
  name: '',
})

let typeP = ref('normal');

// Fetch plan when the component is mounted
onMounted(() => {
  // Fetch plan based on the route parameter
  const route = useRoute();
  var pId = route.params.plan;

  client.value.id = route.params.client
  // pType.value = route.params.plnType
  typeP.value = route.params.plnType

  fetchPlan(pId);
  subscription.plan_id = pId
  subscription.client_id = route.params.client
  subscription.province = route.params.province
  subscription.plan_type = route.params.plnType
  //console.log(subscription.province)
  instalation_tax.value = calcularTaxaInstalacao(route.params.province)

  sequency.value == 2
});

const order = {
  input_amount: 0,
  input_token: "aZS6CXi4WeNuADDiJ0uLPpO6w0uLVRpRILNBj72wCL4nkXYgsYyfXXRCwMxfIO80laNK031Tq6NwnxJXi0FYPlwKbQsYgg3j2RyTT7eUKUfIIktcF3ZLP5tkfVgI8mn5DsLJvtNxkKGNvrVNzaHQOO_1687873308_M-Pesa_|_VIVAnet",
  input_number: ""
};


const calcularTaxaInstalacao = (province) => {
  let instalationTx = 2500;

  switch (province) {
    case 'Maputo':
    case 'Maputo Provincia':
      instalationTx += 0;
      break;
    case 'Gaza':
    case 'Inhambane':
      instalationTx += 750;
      break;
    default:
      instalationTx += 1500;
      break;
  }

  return instalationTx;
};

const fetchPlan = async (planId) => {

  try {
    const response = await axios.get(`https://api.vivanet.co.mz/api/v1/plan/${planId}`);

    plan.value.name = response.data.name,
    plan.value.nominal_speed_download = response.data.nominal_speed_download,
    plan.value.nominal_speed_upload = response.data.nominal_speed_upload,
    plan.value.price = response.data.price,
    plan.value.owner = response.data.owner

    client.value.pacote = response.data.name

  } catch (error) {
    const er = {
      name: 'client',
      message: 'Error fetching plan'
    }
    router.push({ path: '/error', query: { error_obj: JSON.stringify(er) } })
  }
  if(typeP.value == 'normal'){
    sequency.value = 2;
  }
};

const submitForm = async () => {
  loading.value = true;
//  console.log(subscription)

  if (typeP.value == 'normal') {
    order.input_amount = (plan.value.price + instalation_tax.value)
    the_total.value = (plan.value.price + instalation_tax.value);
  } else if (typeP.value == 'flexivel') {
    order.input_amount = (plan.value.price + instalation_tax.value + equipment_price.value)
    the_total.value = (plan.value.price + instalation_tax.value + equipment_price.value);
  } else {
    order.input_amount = (plan.value.price + instalation_tax.value)
    the_total.value = (plan.value.price + instalation_tax.value);
  }
  iva.value = plan.value.price * 0.16;
  order.input_number = '258' + telefone.value

  const endpoint = 'https://api.vivanet.co.mz/api/v1/subscription';

  if (sequency.value == 1) {
    try {
        subscription.client_id = client.value.id
        subscription.paid_value = order.input_amount
        subscription.monthly_payment = plan.value.price
        subscription.payment_method = 'mpesa'
        subscription.input_amount = order.input_number
        subscription.input_number = '258' + telefone.value
        subscription.is_router = is_router.value

        const response = await axios.post('https://api.vivanet.co.mz/api/v1/subscription', subscription);

        router.push({ path: '/success' })
    } catch (error) {
      console.error('Error saving success:', error);
      loading.value = false;
      error_pay.value = true
    }
  } else if (sequency.value == 2) {
    try {
      const inv = {
        name: client.value.first_name + ' ' + client.value.last_name,
        plan: plan.value.name,
        instalation_value: instalation_tax.value + plan.value.price,
      }

      const data_mail = {
        client_id: client.value.id,
        plan_id: plan.value.id,
        instalation_value: instalation_tax.value,
        is_router: is_router.value
      }
      await axios.post('https://api.vivanet.co.mz/api/v1/sendproforma', data_mail);
      // Redirect to success page or perform other actions
      router.push({ path: '/success', query: { invoice: JSON.stringify(inv), bank: 'bank' } })

    } catch (error) {
      const er = {
        name: 'client',
        message: 'Error fetching plan'
      }
      router.push({ path: '/error', query: { error_obj: JSON.stringify(er) } })
    }

  } else if (sequency.value == 3) {
    //console.log('Cartao', subscription)
    payWithMastercard()
  }
};
const mastercard_error = ref('')

/*** PAGAMENTO MASTER CARD ***/

onMounted(() => {
  const script = document.createElement('script');
  script.src = 'https://millenniumbim.gateway.mastercard.com/checkout/version/61/checkout.js';
  script.async = true;
  document.head.appendChild(script);
});

const payWithMastercard = async () => {
  //console.log(subscription)
  let amount = 0
  if (typeP.value == 'flexivel') {
    amount = (plan.value.price + instalation_tax.value + equipment_price.value);
  } else if (typeP.value == 'normal') {
    amount = (plan.value.price + instalation_tax.value);
  } else {
    amount = (plan.value.price + instalation_tax.value);
  }
  let token = 'aZS6CXi4WeNuADDiJ0uLPpO6w0uLVRpRILNBj72wCL4nkXYgsYyfXXRCwMxfIO80laNK031Tq6NwnxJXi0FYPlwKbQsYgg3j2RyTT7eUKUfIIktcF3ZLP5tkfVgI8mn5DsLJvtNxkKGNvrVNzaHQOO_1688482644_Mastercard|_VIVAnet';

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      input_amount: Number(amount).toFixed(2),
      input_token: token,
    }),
  };

  try {
    const response = await fetch('https://pagamentos.interactive.co.mz/api/pay/mastercard', options);
    const result = await response.json();
    const session = result.session.id;

    if (session) {

      subscription.transaction_id = result.successIndicator
      subscription.client_id = client.value.id
      subscription.paid_value = plan.value.price + instalation_tax.value
      subscription.plan_id = plan.value.id
      subscription.monthly_payment = plan.value.price
      subscription.payment_method = 'cartao'

      sessionStorage.setItem("transaction_id", result.successIndicator)
      sessionStorage.setItem("client_id", client.value.id)
      sessionStorage.setItem("paid_value", subscription.paid_value)
      sessionStorage.setItem("plan_id", plan.value.id)
      sessionStorage.setItem("monthly_payment", plan.value.price)
      sessionStorage.setItem("payment_method", subscription.payment_method)
      sessionStorage.setItem("province", subscription.province)
      sessionStorage.setItem("is_router", subscription.is_router)
      // this.pagamentosTemp_ajax(
      //   result.successIndicator,
      //   result.session.version,
      //   result.session.id,
      //   token
      // )
    }

    Checkout.configure({
      merchant: 15413,
      session: {
        id: result.session.id, //session ID generated from the request
      },
      interaction: {
        merchant: {
          name: 'VIVAnet',
          address: {
            line1: 'Maputo',
            line2: 'Maputo cidade',
          },
        },
        locale: 'pt_BR',
        displayControl: {
          billingAddress: 'HIDE',
          customerEmail: 'HIDE',
          shipping: 'HIDE',
        },
      },
    });

    Checkout.showLightbox();

  } catch (error) {

    console.log('error', error);
    mastercard_error.value = error.explanation
  }
};

/*** FIM PAGAMENTO MASTERCARD ***/

onMounted(() => {
  const script = document.createElement('script');
  script.src = 'https://millenniumbim.gateway.mastercard.com/checkout/version/61/checkout.js';

  script.async = true;
  document.head.appendChild(script);
});


</script>

<style lang="css" scoped>
.master-logo {
  width: 120px !important;
}

.bank_card {
  width: 320px !important;
}

.number_format_link {
  width: 120px;
  font-weight: bold;
  color: black;
  border: none;
  pointer-events: none;
  text-align: right;
}

.form-holder {
  background-color: #fff;
  margin-top: 100px;
  padding: 30px;
  border-radius: 30px;
  width: 1200px;
}

.dados {
  font-weight: 800;
  color: #ED1B68;
}

.banco {
  font-weight: 400;
}

.minibold {
  font-weight: 500;
}

.viva-active {
  font-size: 18px;
  border: #ED1B68 solid 2px;
  border-radius: 25px;
}

label {
  color: #364FA2 !important;
  font-weight: 600;
}

input {
  color: #364FA2;
}

.btn-color {
  background-color: #ED1B68;
  border: #ED1B68;
}

input[type=text] {
  color: #364FA2;
  border: 2px solid #364FA2;
}

input[type=email] {
  color: #364FA2;
  border: 2px solid #364FA2;
}

input[type=select] {
  color: #364FA2;
  border: 2px solid #364FA2;
}
</style>