<template>
    <div class="container d-flex justify-content-center">
      <SuccessComponent/>
    </div>
</template>

<script >
import SuccessComponent from '../components/SuccessComponent.vue'

export default {
  name: 'App',
  components: {
    SuccessComponent
  }
}
</script>

<style lang="scss" scoped>

</style>