<template>
  <div class="form-holder">
    <p class="h3 mb-4 dados">Dados do Cliente</p>
    <form class="row g-3" @submit.prevent="submitForm">
      <div class="col-md-6">
        <label for="inputNome" class="form-label">Nome</label>
        <input type="text" class="form-control" id="inputNome" v-model="client.first_name">
      </div>
      <div class="col-md-6">
        <label for="inputApelido" class="form-label">Apelido</label>
        <input type="text" class="form-control" id="inputApelido" v-model="client.last_name">
      </div>
      <div class="col-12">
        <label for="inputEmail" class="form-label">Email</label>
        <input type="email" class="form-control" id="inputEmail" v-model="client.email">
      </div>
      <div class="col-12">
        <label for="inputTelefone" class="form-label">Telefone</label>
        <input type="text" class="form-control" id="inputTelefone" v-model="client.cell">
      </div>
      <div class="col-md-6">
        <label for="inputProvincia" class="form-label">Província</label>
        <select id="inputProvincia" class="form-select" v-model="client.province">
          <option value="Maputo">Maputo Cidade</option>
          <option value="Maputo Provincia">Maputo Província</option>
          <option value="Gaza">Gaza</option>
          <option value="Inhambane">Inhambane</option>
          <option value="Manica">Manica</option>
          <option value="Sofala">Sofala</option>
          <option value="Tete">Tete</option>
          <option value="Zambézia">Zambézia</option>
          <option value="Nampula">Nampula</option>
          <option value="Niassa">Niassa</option>
          <option value="Cabo Delgado">Cabo Delgado</option>
        </select>
      </div>
      <!--   5b4fab9f6f6846ca
      <div class="col-md-6">
        <label for="inputConta" class="form-label">Tipo de Conta</label>
        <select id="inputConta" class="form-select" v-model="client.type" @change="onChange">
          <option value="Institucional">Institucional</option>
          <option value="Individual">Individual</option>
        </select>
      </div> -->

      <hr v-if="visibilidade">

      <div class="col-md-6" v-if="visibilidade">
        <label for="inputNomeEmpresa" class="form-label">Nome da Empresa</label>
        <input type="text" class="form-control" id="inputNomeEmpresa" v-model="client.company_name">
      </div>
      <div class="col-md-6" v-if="visibilidade">
        <label for="inputNuit" class="form-label">NUIT</label>
        <input type="text" class="form-control" id="inputNuit" v-model="client.nuit">
      </div>

      <div class="col-12">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="gridCheck" v-model="termos">
          <label class="form-check-label" for="gridCheck">
            Aceite os nossos <a href="https://vivanet.co.mz/termos-e-condicoes" class="pink-color" target="_blank">termos
              e condições</a>
          </label>
        </div>
      </div>

      <div class="col-12">
        <button type="submit" class="btn btn-primary btn-color" :disabled="!termos">Submeter</button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import axios from 'axios';
import router from '@/router';
import { useRoute } from 'vue-router'; // Corrected import statement
import { ref } from 'vue';

const termos = ref(false);

var client = ref({
  first_name: '',
  last_name: '',
  email: '',
  cell: '',
  province: 'Maputo',
  type: 'Individual',
  company_name: "",
  nuit: "",
  pacote: '',
  status: 1
});
var plan = {}
var planType = ref('')

var visibilidade = false

const submitForm = () => {

  axios.post('https://api.vivanet.co.mz/api/v1/client', client.value)
    .then(response => {
      const clientData = response.data;
      const routeParams = {
        clnt: clientData.id,
        plan: plan.id,
        plnType: planType,
        province: client.value.province
      };

      router.push({ path: `/checkout/client/${routeParams.clnt}/plan/${routeParams.plan}/plan-type/${routeParams.plnType}/province/${routeParams.province}` });
    })
    .catch(error => {
      const er = {
        name: 'client',
        message: 'Error saving client'
      };
      console.log(error)
      router.push({ path: '/error', query: { error_obj: JSON.stringify(er) } });
    })
};


const fetchPlan = async (planId) => {
  try {
    const response = await axios.get(`https://api.vivanet.co.mz/api/v1/plan/${planId}`);
    plan = response.data;
    client.value.pacote = response.data.name
  } catch (error) {
    const er = {
      name: 'client',
      message: 'Error fetching plan'
    }
    router.push({ path: '/error', query: { error_obj: JSON.stringify(er) } })
  }
};

// Fetch plan when the component is mounted
onMounted(() => {
  // Fetch plan based on the route parameter
  const route = useRoute();
  const planId = route.params.id;
  planType = route.params.tipo;

  fetchPlan(planId);
});
</script>

<style lang="css" scoped>
.form-holder {
  background-color: #fff;
  margin-top: 100px;
  padding: 30px;
  border-radius: 30px;
  width: 1000px;
}

.dados {
  font-weight: 800;
  color: #ED1B68;
}

.viva-active {
  font-size: 18px;
  border: #ED1B68 solid 2px;
  border-radius: 25px;
}

label {
  color: #364FA2 !important;
  font-weight: 600;
}

input {
  color: #364FA2;
}

.btn-color {
  background-color: #ED1B68;
  border: #ED1B68;
}

input[type=text] {
  color: #364FA2;
  border: 2px solid #364FA2;
}

input[type=email] {
  color: #364FA2;
  border: 2px solid #364FA2;
}

input[type=select] {
  color: #364FA2;
  border: 2px solid #364FA2;
}

.violet-color {
  color: #364FA2;
}

.pink-color {
  color: #ED1B68;
}</style>