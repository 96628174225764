<template>
    <div class="form-holder">
     <p class="h3 mb-4 dados">Subscrição efectuada com sucesso</p>
    
   </div>
 </template>

 <script setup>

import { onMounted, ref } from 'vue';

import axios from 'axios';

const resultIndicator = ref(null);
const sessionVersion = ref(null);

const subscription = {
    client_id: "",
    plan_id: "",
    start_date: "2023-06-28",
    end_date: "2023-07-28",
    paid_value: 0,
    monthly_payment: 0,
    billing_addres: "",
    transaction_id: "",
    description: null,
    payment_method: "",
    status: 1
}

onMounted(() => {
  // Parsing the query parameters from the URL
  const transaction_id = sessionStorage.getItem('transaction_id');
  const urlParams = new URLSearchParams(window.location.search);
  resultIndicator.value = urlParams.get('resultIndicator');
  sessionVersion.value = urlParams.get('sessionVersion');

  if (transaction_id) {

    subscription.client_id = sessionStorage.getItem('client_id');
    subscription.plan_id = sessionStorage.getItem('plan_id');
    subscription.monthly_payment = sessionStorage.getItem('monthly_payment');
    subscription.paid_value = sessionStorage.getItem('paid_value');
    subscription.transaction_id = transaction_id;
    subscription.payment_method = sessionStorage.getItem('payment_method')

    axios.post('https://api.vivanet.co.mz/api/v1/subscription', subscription);

  }

});


//const route = useRoute()




 </script>

 <style lang="css" scoped>
 .form-holder{
     background-color: #fff;
     margin-top: 100px;
     padding: 30px;
     border-radius: 30px;
     width: 1000px;
 }
 .dados{
     font-weight: 800;
     color: #ED1B68;
 }
 .viva-active{
   font-size: 18px;
   border: #ED1B68 solid 2px;
   border-radius: 25px;
 }
 label {
   color: #364FA2 !important;
   font-weight: 600;
 }
 input{
     color: #364FA2;
 }
 .btn-color{
     background-color: #ED1B68;
     border: #ED1B68;
 }
 input[type=text] {
     color: #364FA2;
     border: 2px solid #364FA2;
 }
 input[type=email] {
     color: #364FA2;
     border: 2px solid #364FA2;
 }
 input[type=select] {
     color: #364FA2;
     border: 2px solid #364FA2;
 }
 </style>