import { createStore } from 'vuex';

const store = createStore({
  state: {
    user: {
      data: {
        name: '',
        role: '',
        email: '',
      },
      token: null,
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.user.token = token;
    },
  },
});

export default store;